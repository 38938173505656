<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userInfo.fullName }}
        </p>
        <span class="user-status">{{ userInfo.role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userInfo.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userInfo.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>
    <b-dropdown-divider />
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
    userInfo:{
      fullName:"",
      role:"",
      avatar:"sasa"
    },
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  created(){
    this.userInfo.fullName = localStorage.getItem('NisourUserName')
    this.userInfo.role = localStorage.getItem('NisourRole')
    if (localStorage.getItem("NisourToken")) {
      this.isLoged = true;
    }else{
      this.isLoged = false
    }
  },
  methods: {
    logout() {
      localStorage.removeItem("NisourUserId")
      localStorage.removeItem("NisourTeacherId")
      localStorage.removeItem("NisourRole")
      localStorage.removeItem("NisourUserName")
      localStorage.removeItem('NisourToken')
      localStorage.removeItem('full_name')
      localStorage.removeItem('NisourStatus')
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
